.container{
    display: flex;
    background-color: #1A1B1D;
    overflow: hidden;
}
.navbar{
    width: 170px;
    height: 100vh;
}
.frame{
    background-color: white;
    width: calc(100% - 170px);
    height: 100vh;
}