.layout{
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
}

.handleFrame{
    width: 50%;
    border-right: 2px solid rgb(230, 230, 230);
    display: flex;
    flex-direction: column;
}
/* addItem */
.addItem{
    width: 100%;
    height: 45%;
    border-bottom: 2px solid rgb(230, 230, 230);
    padding: 50px;
    background-color: #eeeeee28;
}
.addItem > div{
    display: flex;
    margin-bottom: 20px;
    height: 40px;
    border: 2px solid #dadada;
}
.addItem > div label{
    display: flex;
    align-items: center;
    padding: 0 15px;
    background-color: rgb(235, 235, 235);
    font-size: 12px;
    font-weight: bold;
    width: 30%;
    border-right: 2px solid #dadada;
    color: rgb(102, 102, 102);

}

.addItem > div input{
    background-color: rgba(230, 230, 230, 0.075);
    width: 70%;
    font-weight: 600;
    color: rgb(44, 44, 44);
    font-size: 13px;
    font-weight: 500;
    padding: 5px 10px;
    border: none;
    outline: none;
}

.addItem button{
    background-color: black;
    color: white;
    font-weight: bold;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
}


/* listItem */
.listItem{
    height: 55%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #eeeeee28;
}
.item{
    background-color: #a6e9d550;
}

.item,
.listItem_header{
    display: flex;
    width: 100%;   
}
.listItem_header p,
.item p{
    padding: 5px 10px;
    font-size: 13px;
    font-weight: 500;
}

.listItem_header p:nth-child(1),
.item p:nth-child(1){
    width: 40%;
    font-weight: 600;
}
.listItem_header p:nth-child(3),
.item p:nth-child(3){
    background-color: #89f5d479;
}
.listItem_header p:nth-child(2),
.listItem_header p:nth-child(3),
.listItem_header p:nth-child(4),
.item p:nth-child(2),
.item p:nth-child(3),
.item p:nth-child(4){
    width: 15%;
    text-align: center;
}
.listItem_header p:nth-child(5),
.item p:nth-child(5){
    width: 30%;
    text-align: right;
}
.listItem_header p:nth-child(6),
.item p:nth-child(6){
    width: 7%;
    display: flex;
    justify-content: center;
    align-items: center;
}



.listItem_header p{
    font-weight: 600 !important;
}

.listItem_list{
    overflow-y: scroll;
    height: 100%;
}
.listItem_list::-webkit-scrollbar{
    display: none;
}

.noneActiveDiscount{
    background-color: rgba(255, 197, 197, 0.712);
}
.noneActiveDiscount p{
    text-decoration: line-through;
    color: rgb(114, 114, 114);
    font-weight: 400 !important;
}

.listItem_list svg{
    fill: gray;
}



/* detail */
.detail{
    width: 50%;
}

/* modelDelete */
.modelDelete{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #61616181;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modelDelete >div{
    width: 30vw;
    height: 30vh;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.modelDelete >div >div{
    display: flex;
    margin-top: 30px;
}
.modelDelete button{
    background-color: #202020;
    display: flex;
    justify-content: center;
    padding: 5px 10px;
    width: 100px;
    color: white;
    margin: 0 6px;
}