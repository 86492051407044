.layout{
    width: 100%;
    height: 100%;
    display: flex;
}


.frame_1{
    width: 50%;
    max-height: 100vh;
    overflow: scroll;
    border-right: 2px solid  gainsboro;
}
.frame_1 > div{
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 14px;
    padding: 10px 20px;
    background-color: rgb(231, 231, 231);
}
.frame_1 ul{
    overflow-x: scroll;
}
.frame_1 ul::-webkit-scrollbar{
    display: none;
}

.frame_1 li{
    font-weight: 500;
    font-size: 13px;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid gainsboro;
}
