.layout{
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.layout > div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* logo */
.logo{
    width: 100%;
}
.logo p{
    color: rgba(245, 245, 245, 0.603);
    font-weight: 600;
    margin-bottom: 10px;
}


/* input */
.layout > div input{
    width: 300px;
    border: 1px solid black;
    color: white;
    background-color: rgba(110, 110, 110, 0.219);
    border-radius: 5px;
    padding: 7px 20px ;
    margin: 5px 0;
    font-size: 13px;
    border: 1px solid transparent;
    transition:  all 300ms ease;

}
.layout > div input::placeholder{
    color: rgba(245, 245, 245, 0.212);
}
.layout > div input:focus{
    border: 1px solid rgb(207, 207, 207);
    outline: none;
}


.layout > div > div{
    position: relative;
}
.layout > div > div > button{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
}
.layout > div > div > button svg{
    fill: whitesmoke;
}


.layout > div > button{
    border-radius: 5px;
    margin-top: 10px;
    background-color: rgb(255, 255, 255);
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    padding: 9px;
    width: 100px;
}
