

.layout{
    display: flex;
    width: 100%;
    height: 100%;
}

.list{
    display: flex;
    flex-direction: column;
    width: 20%;
}
.id{
    max-height: 100%;
    overflow: scroll;
}
.detail{
    width: 80%;
    background-color: #e0e0e03b;
    border-left: 0.1em solid rgb(207, 207, 207);
    overflow-y: scroll;
}
/* label */
.label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.1em solid rgb(207, 207, 207);
}
.label p{
    padding-left: 8%;
    font-size: 13px;
}

.label button{
    background-color: #dadada;    
    padding: 10px;
    transition: all 300ms ease;
}
.label button:hover{
    background-color: rgba(226, 226, 226, 0.447)
}

/* search */
.search{
    padding: 10px ;
    display: flex;
    align-items: center;
    border-bottom: 1.5px solid rgb(197, 197, 197);
}
.search input{
    border: none;
    outline: none;
    margin-left: 10px;
    font-size: 13px;
    font-weight: 500;
}   

/* item */

.item{
    display: flex;
    align-items: center;
    width: 100%;
}
.item p{
    padding: 8px 0;
    font-weight: 600;
}
.item p:first-child{
    width: 10%;
}
.item p:nth-child(2){
    width: 65%;
    font-size: 11px;
    color: rgb(63, 63, 63);
    text-align: left;
}
.item p:nth-child(3){
    width: 25%;
    font-size: 10px;
}
.item p:nth-child(4){
    width: 25%;
    font-size: 10px;
}

.item{
    background-color: #f0f0f042;
    position: relative;
}

.handle{
    background-color: rgba(235, 172, 172, 0.356);
}

.finish p{
    text-decoration: line-through;
    color: #acacac !important;
}

.item::before{
    z-index: -1;
    content: "";
    background-color: rgba(150, 17, 17, 0.932);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 100%;
    height: 100%;
    transition: all 300ms ease;
}
.nativeLink{
    background-color: rgb(41, 41, 41);
}
.nativeLink p{
    color: white !important;
}
.nativeLink svg{
    fill: white;
}
