
.layout{
    overflow: scroll !important;
    max-height: 100vh;
}

.header,
.item{
    display: flex;
    border-bottom: 2px solid rgb(184, 184, 184);
    padding: 10px 0;
}

.header p{
    font-weight: 700;
}

.header p,
.item p{
    font-size: 14px;

    text-align: center;
}

.header p:nth-child(1),
.item p:nth-child(1){
    width: 5%;
}

.header p:nth-child(2),
.item p:nth-child(2){
    width: 55%;
    text-align: left;
}
.header p:nth-child(3),
.item p:nth-child(3){
    width: 12.5%;
    
}
.header p:nth-child(4),
.item p:nth-child(4){
    width: 12.5%;
}
.header p:nth-child(5),
.item p:nth-child(5){
    width: 10%;
}

.header p:last-child,
.item p:last-child{
    width: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*model*/
.model{
    z-index: 1000;
    position: absolute;
    width: calc(100% - 170px);
    height: 100%;
    background-color: rgba(0, 0, 0, 0.452);
    display: flex;
    justify-content: center;
    align-items: center;
}
.model > div{
    background-color: rgb(0, 0, 0);
    width: 30%;
    height: 40%;
    border-radius: 5px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.model > div svg{
    position: absolute;
    top: 15px;
    right: 15px;
    fill: white;
}
.model > div > div{
    display: flex;
    align-items: center;
    padding: 0 10px;
    background-color: rgba(218, 218, 218, 0.233);
}
.model > div > div input{
    background-color: transparent;
    border: none;
    outline: none;
    padding: 5px 0;
    color: white;
    width: 100%;
}
.model > div > div button{
    color: red;
    font-size: 15px;
} 