.layout{
    width: 100%;
    height: 100%;
    background-color: #ececec;
    padding: 5vh 5vw;
    overflow-x: scroll;
}

.closeBtn{
    font-size: 11px;
    margin-bottom: 5px;
    margin-left: 5px;
}
.form{
    border-radius: 7px ;
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.form section{
    border-bottom: 2px solid #ececec;
    width: 100%;
    padding: 30px 50px;
    /* padding: 10px 50px 30px; */
    display: flex;
    flex-direction: column;
    align-items: baseline;
}
.form section:last-child{
    border-bottom: none;
}

.form section > div{
    display: flex;
    position: relative;
}
.form section > div > div{
    position: relative;
}
.form section > div > div:not(:first-child){
    margin-left: 30px;
}

.form section > div > div:first-child{
    margin-bottom: 30px;
}

.form input,
.form textarea{
    outline: none;
    border: none;
    background-color: #eeeeee75;
    border-radius: 3px;
    padding:  5px  10px;
    border: 2px solid transparent;
    transition: all 300ms ease;
}

.form input:focus,
.form textarea:focus{
    border: 2px solid #2254b244;
    border-radius: 3px;
}

.form input[type='text']{
    width: 230px;
}
.form input[type='number']{
    width: 100px;
}


.form select{
    background-color: #eeeeee75;
    outline: none;
    border: none;
    padding: 10px 0;
}
.form .optionFrame select{
    width: 130px;
    padding: 10px;
}

.form  label {
    font-size: 13px;
    font-weight: 600;
    display: flex !important;
    color: rgb(78, 78, 78);
}
.form section > label{
    margin-bottom: 15px;
}
.form section div label {
    margin-bottom: 10px;
}

/* description */
.description {
    width: 100%;
    position: relative;
}
.description::after {
    content: "";
    position: absolute;
    right: 50%;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: #dfdfdf;
}

.description > div{
    width: 100%;
    flex-direction: row ;

}
.description > div > div{
    width: 100%;
    padding: 0 30px;
    min-height: 200px;
}



.description > div > div > div{
    position: relative;
    display: flex;
    align-items: center;
}

.description textarea{
    width: 100% !important;
    height: 70px;
    min-height: 70px;
}

.description > div > div > div > button{
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
    height: 40px;
    border-radius: 3px;
    color: white;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.description ul li{
    font-size: 14px;
    padding: 2px 10px;
    height: 50px;
    border: 1px solid #c7c7c7;
    border-top: none;
    display: flex;
    align-items: baseline;
    position: relative;
}
.description ul li:first-child{
    border-top:  1px solid #c7c7c7;
}
.description ul li > button{
    position: absolute;
    right: 7px;
    top: 7px;
    opacity: 0.7;
}
/* color */
.color{
    position: relative;
}



/* .related */
.related{
    width: 100%;
    min-height: 300px;
    padding-bottom: 100px !important;
    position: relative;
}
.related > div > div{
    width: 300px;
    position: relative;
    display: flex;
    height: 100%;
}
.related > div > div input{
    margin-right: 10px;
}

.related > div > div > button{
    border-radius: 3px;
    color: white;
    padding: 0 30px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}


.related > div{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.related > div > ul li{
    display: flex;
    align-items: center;
    width: 50%;
    border: 1px solid #c7c7c7;
    border-bottom: none;
    position: relative;
}
.related > div > ul li:last-child{
    border-bottom: 1px solid #c7c7c7;
}

.related > div > ul li div{
    display: flex;
    justify-content: center;
    align-items: center;
}

.related > div > ul li span{
    padding: 0 !important;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 30px 0 0 !important;
    border-right: 1px solid #c7c7c7;
}
.related > div > ul li > button{
    position: absolute;
    right: 15px;
}
.related > div > ul li p{
    height: 100%;
    margin-right: 50px;
    font-size: 14px;
}
.related > div > ul img{
    width: 50px;
    height: 50px;
}
.related > ul li > div{
    display: flex;
    align-items: center;
}
.related > ul li > div > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10px;
}
.related > ul{
    overflow-y: scroll;
    overflow-x: hidden !important;
}
.related > ul::-webkit-scrollbar{
    display: block !important;
}

.related > ul li span{
    font-size: 10px;
    color: #8f8f8f;
}
.related > ul li img{
    width: 50px;
    height: 50px;
    border: 2px solid rgba(75, 75, 75, 0.164);
    border-radius: 5px;
}

/* color + related */
.color ul,
.related > ul{
    background-color: rgb(243, 243, 243);
    position: absolute;
    right: 0;
    top: 0;
    overflow-x: scroll;
    z-index: 10;
    width: 30%;
    height: 100%;
}
.color ul li,
.related > ul li{
    font-size: 13px;
    padding: 5px 10px;
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.color ul li:hover,
.related > ul li:hover{
    background-color: #c5c5c5;
}

.color  ul li:not(:last-child),
.related > ul li:not(:last-child){
    border-bottom: 1px solid gainsboro;
}



/* imgFrame */

.imgFrame table th,
.imgFrame table td {
    font-weight: 600;
    font-size: 15px;
}
.imgFrame table th{
    text-align: left;
    padding-left: 30px;
}


.imgFrame table td:nth-child(1){
    width: 110px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eeeeee75;
}

.imgFrame table td:nth-child(2),
.imgFrame table td:nth-child(3){
    width: 300px;
    height: 110px;
    padding-left: 30px;
}
.imgFrame table img{
    width: 110px;
    height: 110px;
}



/* btnFrame */
.btnFrame{
    align-items: flex-end !important;
}
.btnFrame > div button{
    border-radius: 3px;
    color: white;
    padding: 8px 30px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    margin:  0 5px;
    transition: all 300ms ease;
    overflow: hidden !important;
}
.btnFrame > div button:active{
    padding: 8px 20px;
}

.btnFrame .deleteBtn{
    background-color: rgba(212, 10, 10, 0.877);
}

.btnFrame > div .activeSave{
    width: 50px;
    padding: 8px 50px;
    transition: all 450ms ease;
    
}

.btnFrame > div .noneActivesave{
    width: 0;
    padding: 8px 0 ;

}

/* layout_model_delete */
.layout_model_delete{
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: black;
    padding-right: 10vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.layout_model_delete > div{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.layout_model_delete p{
    color: white;
}
.layout_model_delete > div > div{
    display: flex;
    justify-content: center;
}
.layout_model_delete button{
    margin: 5px 10px;
    padding: 7px 30px;
    background-color: white;
    font-weight: 500;
}
.layout_model_delete svg{
    width: 80px;
    height: 80px;
}
/* .layout_model_delete */
.layout_model_edit{
    position: fixed;
    top: 0;
    z-index: 100;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #8f8f8f36;
    display: flex;
    justify-content: center;
    align-items: center;
}
