.layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1vh;
    padding-bottom: 10vh;
}

.layout h3{
    margin-bottom: 20px;
}

.layout > div{
    background-color: white;    
    width: 80%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    margin: 10px 0;
}

/* headerFrame */
.headerFrame{
    display: flex;
}
.headerFrame p{
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
}
.headerFrame  svg{
    margin: 0 10px;
    fill: rgba(66, 66, 66, 0.89);
}
.headerFrame select{
    padding: 10px;
    margin-right: 15px;
    outline: none;
    border: none;
    font-weight: 600;
    background-color: black;
    color: rgb(218, 227, 228);
}
.headerFrame option{
    background-color: white;
}



/* infoFrame */
.infoFrame{
    padding: 30px;
}
.infoFrame > div{
    display: flex;
}
.infoFrame > div > p{
    width: 100%;
}

.infoFrame p{
    border: 2px solid #e0e0e031;
    background-color: #e0e0e025;
    padding: 23px 10px 0;
    margin: 10px 5px;
    font-size: 14px;
    position: relative;
    font-weight: 500;
    color: rgb(77, 77, 77);
}
.infoFrame p span{
    position: absolute;
    top: 3px;
    font-size: 10px;
    color: rgb(90, 90, 90);
    text-decoration: underline;
}
/* useDiscountCode */
.useDiscountCode{
    background-color: rgba(235, 235, 235, 0.473);
    padding: 20px 30px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 30px;
}
.useDiscountCode span{
    font-weight: bold;
}
.useDiscountCode span:last-child{
    margin-left:  50px;
}

/* cartFrame */
.cartFrame{
    padding: 30px;
}

.calcCartFrame{
    font-size: 14px;
    font-weight: 500;
    color: rgb(77, 77, 77);
    background-color: rgba(235, 235, 235, 0.473);
    padding: 30px;
}
.calcCartFrame p{
    display: flex;
}
.calcCartFrame p > span{
    margin-left: 9px;
}
/* cartItem */
.cartItem{
    display: flex;
    padding: 0 20px 30px ;
}
.cartItem:not(:last-child){
    margin-bottom: 30px;
    border-bottom: 2px solid gainsboro;
}

.cartItem img{
    height: 100px;
}

.cartItem > div{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 5%;
    font-size: 14px;
    font-weight: 500;
    color: rgb(77, 77, 77);
}
.cartItem > p{
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    width: 20%;
    font-size: 14px;
    font-weight: 500;
    color: rgb(77, 77, 77);
}
