.container{
    width: 100%;
}

.btnList{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 2vw;
    height: 60px;
}

.addNew {
    width: 200px;
    display: flex;
    justify-content: center;
}
.addNew a{
    background-color: #2d2d2d;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 6px 35px;
    font-size: 12.5px;
    font-weight: 500;
    color: white;
    transition: all 300ms ease;
}
.addNew a:active{
    padding: 5px 28px;
    transition: all 300ms ease;
}


/* ==== */
.data{
    max-height: calc(100vh - 60px - 50px);
    padding-bottom: 50px;
    overflow-x: scroll;
}

