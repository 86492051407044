.container{
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 30px 0;
    position: relative;
}
.container a {
    display: flex;
    justify-content: space-between;
}
.container a:active {
    opacity: 0.2;
}
.container a span{
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.5px;
    margin: 5px 0;
    padding: 5px 15px;
    color: rgba(255, 255, 255, 0.918);
}

.activeLink{
    background-color: #183f46;
    transition: all 300ms ease;
}
.noneActiveLink{
    background-color: transparent;
    transition: all 300ms ease;
}


/* logout */
.logout{
    position: absolute;
    bottom: 0;
    width: 100%;
    color: white;
    padding: 5px;
    font-weight: 500;
    background-color: #182b2e;
}

/* layout_model */
.layout_model{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: black;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}
.layout_model >div{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.layout_model >div > div{
    display: flex;
    margin-top: 10px;
}

.layout_model p,
.layout_model button{
    color: white;
}
.layout_model button{
    padding: 10px;
    margin: 0 10px;
    background-color: rgba(216, 216, 216, 0.144);
}

