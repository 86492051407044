body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


:root{
  --gray-border-1: #e4e4e4;
}


*,
*::after,
*::before{
  margin: 0;
  padding: 0;
  letter-spacing: 0.06em;
  line-height: 1.6;
  text-decoration: none;
  list-style: none;
}

*{
  font-family: 'Nunito', sans-serif;
  letter-spacing: 0.3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  color: #2d2d2d;

}

html{
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  font-size: 17.5px;
}

::-webkit-scrollbar{
  display: none;
}



body::after{
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  border-top:  1px solid var(--gray-border-1);
}


/* *::selection{
  background-color: #568ea769;
} */

button{
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
}


body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


:root{
  --gray-border-1: #e4e4e4;
}


*,
*::after,
*::before{
  margin: 0;
  padding: 0;
  letter-spacing: 0.06em;
  line-height: 1.6;
  text-decoration: none;
  list-style: none;
}

*{
  font-family: "Inter", sans-serif;
  letter-spacing: 0.3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  color: #2d2d2d;

}

html{
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  font-size: 17.5px;
}

::-webkit-scrollbar{
  width: 14px;
}
::-webkit-scrollbar-track{
  background-color: rgba(211, 211, 211, 0.8);
}
::-webkit-scrollbar-thumb{
  background-color:  rgba(128, 128, 128, 0.4);
}
::-webkit-scrollbar-thumb:hover{
  background-color:  rgba(128, 128, 128, 0.6);
}


body::after{
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  border-top:  1px solid var(--gray-border-1);
}


/* *::selection{
  background-color: #568ea769;
} */

button{
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
}




