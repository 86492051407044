.container{
    display: flex;
    border-bottom: 1.5px solid var(--gray-border-1);
    -webkit-user-select: none; 
    user-select: none;
}
.container:hover:not(.header) {
    background-color: #78a3d31a;
}
.container > div{
    display: flex;
    padding: 10px 1vw;
}

.container > div p,
.container > div table td {
    font-size: 13px;
    color: rgb(107, 107, 107);
    font-weight: 600;
}
.header{
    font-weight: 600;
}




/* width */
.container > div:nth-child(1){
    width: 7%;
}
.container > div:nth-child(2){
    width: 13%;
}
.container > div:nth-child(3){
    justify-content: center;
    width: 5%;
}
.container > div:nth-child(4){
    width: 10%;
}
.container > div:nth-child(5){
    width: 10%;
    justify-content: center;
}

.container > div:nth-child(6){
    width: 10%;
    justify-content: center;
}

.container > div:nth-child(7){
    width: 20%;
}
.container > div:nth-child(8){
    width: 20%;
    max-width: 20%;
    padding-left: 5%;
}

/* style childern */
.container > div:nth-child(1){
    flex-direction: column;
}
.container > div:nth-child(1) p:nth-child(2){
    font-weight: 600;
    font-size: 10px;
    opacity: 0.8;
    word-break: break-all;
}

.container > div:nth-child(4){
    flex-direction: column;
    align-items: flex-end;
}
.container > div:nth-child(4) p {
    display: flex;
    align-items: center;
}
.container > div:nth-child(4) p  span{
    color: rgb(107, 107, 107);
}
.container > div:nth-child(4) p > span:last-child{
    display: block;
    width: 30px;
    font-size: 10px;
    margin-left: 5px;
    opacity: 0.5;
}
.container > div:nth-child(4) svg{
    opacity: 0.7;
}

.container > div:nth-child(5) {
    position: relative;
    background-color: rgba(226, 226, 226, 0.426);
}


.container > div:nth-child(5) p{
    text-align: center;
    width: 100%;
}


.container > div:nth-child(7) table{
    width: 100%;
}

.container > div:nth-child(7) td,
.container > div:nth-child(7) p{
    width: 25%;    
    white-space: nowrap;
    text-align: center;
}


.container > div:nth-child(7) table td{
    text-align: center;
    color: rgb(247, 12, 12);
    font-size: 12px;
    font-weight: 500;
}


.container > div:nth-child(8){
    justify-content: flex-end;
    flex-wrap: wrap;
} 
.container > div:nth-child(8) img{
    width: 40px;
    height: 40px;
}

/* nullSize */
.nullSize{
    color: red !important;
}